import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const DataProtectionPage = ({ data: { dataProtection } }) => (
    <article className="sheet">
        <HelmetDatoCms seo={dataProtection.seoMetaTags} />
        <div className="sheet__inner">
            <h1 className="sheet__title">{dataProtection.title}</h1>
            <div
                className="sheet__body"
                dangerouslySetInnerHTML={{
                    __html: dataProtection.content,
                }}
            />
        </div>
    </article>
)

export default DataProtectionPage

export const query = graphql`
  query DataProtectionQuery {
    dataProtection: datoCmsContentPage(title: {eq: "Datenschutz"}) {
        title
        content
        seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
        }
    }
  }
`
